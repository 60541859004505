import React from 'react'
import './faq.css'
import { Accordion } from 'react-bootstrap'

const Faq = () => {
  return (
    <div className="faq_block" id={'faq'}>
      <div className="customContainer">
        <div className="faq_title_block">
          <div>
            <p className="faq_title">Залишилися запитання?</p>
          </div>
        </div>
        <div className="customContainerFaq">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <p className="faq_text_title">
                  Скільки часу займає створення інтернет-магазину?
                </p>
              </Accordion.Header>
              <Accordion.Body>
                Зазвичай, створення автоматизованого інтернет-магазину займає
                від одного місяця. Час реалізації залежить від обсягу необхідних
                функцій, кількості інтеграцій та рівня кастомізації.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <p className="faq_text_title">
                  Чи можу я самостійно керувати магазином після запуску?
                </p>
              </Accordion.Header>
              <Accordion.Body>
                Так, наші магазини розроблені з урахуванням зручності
                управління. Після запуску ви зможете самостійно оновлювати
                товари, стежити за замовленнями, аналізувати дані про продажі та
                керувати акціями без технічної підтримки.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <p className="faq_text_title">
                  Які інтеграції можливі з іншими сервісами?
                </p>
              </Accordion.Header>
              <Accordion.Body>
                Ми підтримуємо інтеграції з багатьма популярними платформами,
                такими як Prom, Google Analytics, CRM-системи, платіжні шлюзи,
                системи обліку. Це дозволяє вам автоматизувати та розширити
                функціонал вашого магазину.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <p className="faq_text_title">
                  Чи забезпечується безпека даних у магазині?
                </p>
              </Accordion.Header>
              <Accordion.Body>
                Так, безпека даних є пріоритетом. Ми впроваджуємо сучасні методи
                шифрування, регулярні оновлення системи безпеки та
                багаторівневий контроль доступу, щоб захистити дані ваших
                клієнтів і вашу комерційну інформацію.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <p className="faq_text_title">
                  Як оптимізувати інтернет-магазин для SEO?
                </p>
              </Accordion.Header>
              <Accordion.Body>
                Наші інтернет-магазини мають вбудовані інструменти для
                SEO-оптимізації. Ви зможете додавати мета-теги, URL-адреси, що
                легко читаються, і зображення з альт-текстом, а також
                налаштовувати швидкість завантаження сторінок для покращення
                видимості у пошукових системах.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <p className="faq_text_title">
                  Як підтримувати та оновлювати інтернет-магазин після запуску?
                </p>
              </Accordion.Header>
              <Accordion.Body>
                Після запуску ми надаємо технічну підтримку та регулярні
                оновлення, щоб ваш магазин залишався безпечним і функціональним.
                Ви також зможете самостійно оновлювати контент і додавати нові
                товари. Крім того, ми пропонуємо послуги підтримки для
                подальшого розвитку магазину та інтеграцій нових функцій.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  )
}
export default Faq
