import React from 'react'
import TextItem from '../../../Components/TextItem/TextItem'
import styles from './ERP.module.css'
import ReviewCard from '../../../Components/ReviewCard/ReviewCard'
import {
  ERPIcon,
  ERPIcon1,
  ERPIcon2,
  ERPIcon3,
  ERPImg,
  ERPImgIcon,
  Kurasevich,
} from '../../../Assets/Icons/ERP/Index'

const ERP = () => {
  const content = {
    subtitle: 'ERP-система',
    subtitleImage: ERPIcon, // Імпортоване зображення іконки
    title: 'Аналізуй продажі та працюй з базою клієнтів в ERP-системі',
    description:
      'Керуй усіма аспектами свого бізнесу в одному місці за допомогою інтегрованої ERP-системи.',
    benefits: [
      'Збирай усю інформацію про покупки кожного клієнта',
      'Роби вибірку клієнтської бази для розсилок через Email, Viber та SMS',
      'Аналізуй прибутковість від кожного клієнта окремо',
    ],
    servicesText: 'Aima підтримує такі сервіси:',
    services: [
      { name: 'Google Analytics', logo: ERPIcon1 },
      { name: 'Facebook Pixel', logo: ERPIcon2 },
      { name: 'Google Tag Manager', logo: ERPIcon3 },
    ],
    buttonText: 'Спробувати aima',
  }
  const review = {
    image: Kurasevich,
    name: 'Курасевич Віталій',
    position: 'Власник інтернет-магазину ”RIXUS”',
    stars: 5,
    text: 'Вести бізнес стало набагато простіше завдяки ERP-системі! Тепер усі процеси – від замовлень до фінансів – в одному місці. Більше не треба користуватися різними програмами, все працює злагоджено й швидко. Це економить час і дає можливість зосередитися на важливих справах.',
  }

  return (
    <div className={styles.hidden} id={'erp'}>
      <div className={`${styles.container} ${'customContainer'}`}>
        <TextItem content={content} />
        <img src={ERPImg} alt="imageIcon" className={styles.image} />
      </div>
      <div className={`${styles.wrap} ${'customContainer'}`}>
        <ReviewCard review={review} />
        <img src={ERPImgIcon} alt="imageIcon" className={styles.imageIcon} />
      </div>
    </div>
  )
}

export default ERP
