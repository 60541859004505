import React, { useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { toast } from 'react-toastify'
import { Toastify } from '../../Components/Toastify/Toastify'
import { sendInTg, sendInCrm } from '../../Constants'
import 'react-international-phone/style.css'
import { PhoneInput } from 'react-international-phone'
import styles from './Form.module.css'
import { useNavigate } from 'react-router-dom'

const getUTMParams = () => {
  const urlParams = new URLSearchParams(window.location.search)
  return {
    utm_source: urlParams.get('utm_source') || '',
    utm_medium: urlParams.get('utm_medium') || '',
    utm_campaign: urlParams.get('utm_campaign') || '',
    utm_term: urlParams.get('utm_term') || '',
    utm_content: urlParams.get('utm_content') || '',
  }
}

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, touchedFields },
  } = useForm({ mode: 'all' })

  const [phone, setPhone] = useState('')
  const [isBlurredPhone, setIsBlurredPhone] = useState(false)
  // const [isSubmitted, setIsSubmitted] = useState(false) // State to track submission status

  const isPhoneValid = (phone) => {
    const phoneUtil = PhoneNumberUtil.getInstance()
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
    } catch (error) {
      return false
    }
  }
  const navigate = useNavigate()

  const isValid = isPhoneValid(phone)
  const toastId = useRef(null)

  const onSubmit = async ({ name, email }) => {
    if (isValid) {
      if (toastId.current) {
        toast.dismiss(toastId.current)
      }
      toastId.current = toast(<Toastify type={'pending'} />, {
        autoClose: false,
        hideProgressBar: true,
      })

      const utmParams = getUTMParams()

      // Додаємо UTM мітки до повідомлення в Telegram
      let msg = `Заявка на консультацію:\nName - ${name}\nPhone - ${phone}\nEmail - ${
        email || 'не вказано'
      }\nUTM Source - ${utmParams.utm_source}\nUTM Medium - ${
        utmParams.utm_medium
      }\nUTM Campaign - ${utmParams.utm_campaign}\nUTM Term - ${
        utmParams.utm_term
      }\nUTM Content - ${utmParams.utm_content}`

      let crmData = {
        ...utmParams, // Додаємо UTM мітки до CRM
        lead_name: name,
        lead_phone: phone,
        lead_email: email || '', // email може бути необов'язковим
        lead_source: 'Сайт AIMA',
      }

      try {
        let respTg = await sendInTg(msg) // Надсилаємо повідомлення з UTM мітками у Telegram
        let respCrm = await sendInCrm(crmData) // Надсилаємо дані в CRM
        // console.log('CRM Response:', respCrm) // Логування відповіді CRM

        if (respCrm || respTg.ok) {
          toast.update(toastId.current, {
            render: <Toastify type={'success'} />,
            type: 'success',
            icon: false,
            autoClose: 3000,
            hideProgressBar: false,
          })
        }
      } catch (e) {
        toast.update(toastId.current, {
          render: 'Упс, щось пішло не так з відправкою...',
          type: 'error',
          autoClose: 7000,
        })
      }

      reset()
      setPhone('')
      setIsBlurredPhone(false)
      // handleClose() // Закриваємо попап
      navigate('/thankyou') // Переадресація на сторінку "Дякую"
    } else {
      setIsBlurredPhone(true)
    }
  }

  const redBord = {
    boxShadow: 'inset 0px 0px 5px 5px rgba(255,0,0,.2)',
  }

  const greenBord = {
    boxShadow: 'inset 0px 0px 5px 5px rgba(100,255,100,.2)',
  }

  return (
    <section className={styles.contactSection} id={'form'}>
      <div className={'customContainer'}>
        {/*{isSubmitted ? ( // Conditional rendering*/}
        {/*  <p className={styles.title}>*/}
        {/*    Дякуємо, ваша заявка успішно відправлена*/}
        {/*  </p>*/}
        {/*) : (*/}
        {/*  <>*/}
        <p className={styles.title}>Замовлення зворотного зв’язку</p>
        <p className={styles.subtitle}>
          Замовляй зворотний зв'язок щоб дізнатись про інтернет-магазини від
          АІМА
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <div className={styles.formGroup}>
            <label htmlFor="name" className={styles.label}>
              Ім'я:
            </label>
            <input
              style={
                errors?.name && touchedFields?.name
                  ? redBord
                  : !errors?.name && touchedFields?.name
                  ? greenBord
                  : null
              }
              type="text"
              id="name"
              placeholder="Уведіть ваше ім'я"
              className={styles.input}
              {...register('name', {
                required: true,
                minLength: 2,
                maxLength: 30,
              })}
            />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="Phone" className={styles.label}>
              Телефон:
            </label>
            <PhoneInput
              style={
                !isValid && isBlurredPhone
                  ? redBord
                  : isValid && isBlurredPhone
                  ? greenBord
                  : null
              }
              defaultCountry="ua"
              className={styles.input}
              onChange={(e) => setPhone(e)}
              value={phone}
              onFocus={() => setIsBlurredPhone(true)}
            />
          </div>

          <button type="submit" className={styles.button}>
            Надіслати
          </button>
        </form>
        {/*  </>*/}
        {/*)}*/}
      </div>
    </section>
  )
}

export default ContactForm
