import { baseURL, chatId, crmURL, validator } from './urls'

const sendInTg = async (msg) => {
  return await fetch(baseURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      validator: validator,
      chat_id: chatId,
      message: msg,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response
    })
    .catch((error) => {
      console.error('Помилка при виконанні запиту:', error)
    })
}

const sendInCrm = async (msg) => {
  try {
    const response = await fetch(crmURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(msg),
      mode: 'no-cors',
    })

    // // Логування статусу та тексту відповіді
    // console.log('CRM Response status:', response)

    if (!response.ok) {
      // Отримуємо текст помилки з відповіді
      const errorText = await response.text()
      console.error('Error response from CRM:', errorText)
      throw new Error(`Network response was not ok: ${errorText}`)
    }

    const responseData = await response.json() // Припустимо, сервер повертає JSON
    return responseData // Повертаємо дані, якщо відповідь успішна
  } catch (error) {
    console.error('Помилка при відправці у CRM:', error)
    throw error // Пробрасываем ошибку дальше
  }
}

export { sendInTg, sendInCrm }
