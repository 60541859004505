import React from 'react'
import styles from './TextItem.module.css' // Підключаємо CSS модуль

const TextItem = ({ content, customClasses = {} }) => {
  return (
    <div className={`${styles.container} ${'customContainer'}`}>
      <div
        className={`${styles.subtitleContainer} ${
          customClasses.subtitleContainer || ''
        }`}
        style={{
          display: content.subtitle || content.subtitleImage ? '' : 'none',
        }}
      >
        {content.subtitleImage && (
          <img
            src={content.subtitleImage}
            alt="subtitle"
            className={`${styles.subtitleImage} ${
              customClasses.subtitleImage || ''
            }`}
          />
        )}
        {content.subtitle && (
          <p className={`${styles.subtitle} ${customClasses.subtitle || ''}`}>
            {content.subtitle}
          </p>
        )}
      </div>

      {/* Title */}
      <h2
        className={`${styles.title} ${customClasses.title || ''}`}
        style={{ display: content.title ? '' : 'none' }}
      >
        {content.title}
      </h2>

      {/* Description */}
      <p
        className={`${styles.description} ${customClasses.description || ''}`}
        style={{ display: content.description ? '' : 'none' }}
      >
        {content.description}
      </p>

      {/* Benefits List */}
      <ul
        className={`${styles.benefitsList} ${customClasses.benefitsList || ''}`}
        style={{
          display:
            content.benefits && content.benefits.length > 0 ? '' : 'none',
        }}
      >
        {content.benefits?.map((benefit, index) => (
          <li
            key={index}
            className={`${styles.benefitItem} ${
              customClasses.benefitItem || ''
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <path
                d="M7 14L11.6667 20L21 8"
                stroke="#0672D5"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>{' '}
            {benefit}
          </li>
        ))}
      </ul>

      {/* Services Text */}
      <p
        className={`${styles.servicesText} ${customClasses.servicesText || ''}`}
        style={{ display: content.servicesText ? '' : 'none' }}
      >
        {content.servicesText}
      </p>

      {/* Services */}
      <div
        className={`${styles.services} ${customClasses.services || ''}`}
        style={{
          display:
            content.services && content.services.length > 0 ? '' : 'none',
        }}
      >
        {content.services?.map((service, index) => (
          <div
            key={index}
            className={`${styles.serviceItem} ${
              customClasses.serviceItem || ''
            }`}
          >
            <img
              src={service.logo}
              alt={service.name}
              className={`${styles.serviceImage} ${
                customClasses.serviceImage || ''
              }`}
            />
          </div>
        ))}
      </div>

      {/* Button */}
      <a
        href={content.href}
        className={`${styles.button} ${customClasses.button || ''}`}
        style={{ display: content.buttonText ? '' : 'none' }}
      >
        <iframe title="Lottie animation" className={styles.icon} src="https://lottie.host/embed/f3bcfdd8-85de-43e2-ba88-223e558b8fe6/loLsyG2Vxx.json"></iframe>
        {content.buttonText}
      </a>
    </div>
  )
}

export default TextItem
