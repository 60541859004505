import React from 'react'
import './integrations.css'
import {
  Analytics,
  Binotel,
  Facebook,
  Google,
  Liqpay,
  Mono,
  Nova,
  TagManager,
  Ukr_post,
  WayPay,
} from '../../../Assets/Images/Integration'

const Integrations = () => {
  return (
    <div className="integration_block_padding" id={'integrations'}>
      <div className="customContainer customContainer_mobile  integration_block">
        <div>
          <h2 className="integration_title">
            В aima доступна інтеграція з наступними сервісами:
          </h2>
        </div>
        <div className="integration_img_block">
          <img className="integration_img" src={Nova} alt="Nova" />
          <img className="integration_img" src={Ukr_post} alt="Ukr_post" />
          <img className="integration_img" src={Mono} alt="Mono" />
          <img className="integration_img" src={Liqpay} alt="Liqpay" />
          <img className="integration_img" src={WayPay} alt="WayPay" />
          <img className="integration_img" src={Google} alt="WayPay" />
          <img className="integration_img" src={Analytics} alt="Analytics" />
          <img className="integration_img" src={Facebook} alt="Facebook" />
          <img className="integration_img" src={TagManager} alt="TagManager" />
          <img className="integration_img" src={Binotel} alt="Binotel" />
        </div>
      </div>
    </div>
  )
}

export default Integrations
