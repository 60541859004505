import React, { useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import './whyus.css'
import { WhySlide1, WhySlide2, WhySlide3 } from '../../../Assets/Images/WhyUs'

const Why = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const slides = [WhySlide1, WhySlide2, WhySlide3] // Масив зображень

  // Функція для перемикання слайдів вперед
  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length)
  }

  // Функція для перемикання слайдів назад
  const handlePrevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length)
  }

  // Логіка для свайпів
  const handlers = useSwipeable({
    onSwipedLeft: handleNextSlide,
    onSwipedRight: handlePrevSlide,
  })

  return (
    <div className="whyus_block" id={'why'}>
      <div className="customContainer whyus_title_block">
        <div className="whyus_subtitle">
          <p className="whyus_subtitle_text">Чому ми?</p>
        </div>
        <p className="whyus_title">
          Обирай рішення, які вже перевірені на багатьох бізнесах
        </p>
      </div>
      <div className="whyus_slider" {...handlers}>
        <button className="prev" onClick={handlePrevSlide}></button>
        <div className="whyus_slide_container">
          {slides.map((slide, index) => {
            const position =
              index === currentSlide
                ? 'active'
                : index === (currentSlide - 1 + slides.length) % slides.length
                ? 'left'
                : 'right'

            return (
              <img
                key={index}
                className={`whyus_slide_img ${position}`}
                src={slide}
                alt={`WhySlide${index + 1}`}
              />
            )
          })}
        </div>
        <button className="next" onClick={handleNextSlide}></button>
      </div>
    </div>
  )
}

export default Why
