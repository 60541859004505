import React, { useEffect } from 'react'
import MainBlock from './MainBlock/MainBlock'
import Website from './Website/Website'
import ERP from './ERP/ERP'
import Integrations from './Integrations/Integrations'
import Faq from './Faq/Faq'
import CRM from './CRM/CRM'
import Offer from './Offer/Offer'
import Advantages from './Advantages/Advantages'
import Why from './WhyUs/Why'
import HowItWorks from './HowItWorks/HowItWorks'
import Form from '../../Modules/Form/Form'

const MainPage = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = '//widgets.binotel.com/getcall/widgets/ws7qbpremcgwhmpihc7i.js'

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div>
      <MainBlock />
      <Offer />
      <HowItWorks />
      <Website />
      <CRM />
      <ERP />
      <Why />
      <Integrations />
      <Advantages />
      {/* Блок з кнопкою Binotel */}
      <div id="binotel-call-button"></div>
      <Form />
      <Faq />
    </div>
  )
}

export default MainPage
